import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Table } from '@aws-northstar/ui/components'
import { Button, Icon, Link, Popover, StatusIndicator } from '@cloudscape-design/components'

import LoadingSpinner from '../../components/LoadingSpinner'

import useApplications from '../../hooks/query/useApplications'
import type { Application } from '../../types/applications'
import CONSTANTS from '../../constants'

const ApplicationOnboarding: FC = () => {
	const navigate = useNavigate()

	const { data: applications, isLoading } = useApplications()
	// if (!applications || isLoading) return <LoadingSpinner />
	const columnDefinitions = [
		{
			id: 'applicationID',
			width: 100,
			header: 'ID',
			cell: (data: any) => (
				<Link onFollow={() => navigate(data.applicationID) }>
					{data.applicationID}
				</Link>
			),
			sortingField: 'applicationID'
		},
		{
			id: 'applicationName',
			width: 100,
			header: 'Application Name',
			cell: (data: Application) => data.details.applicationName || '',
			// sortingField: 'details.applicationName'
		},
		{
			id: 'environment',
			width: 100,
			header: 'Environment',
			cell: (data: Application) => {
				return data.details.additionalEnvName
					? data.details.additionalEnvName
					: data.details.environment.value || ''
			}
		},
		{
			id: 'requestor',
			width: 100,
			header: 'Requested by',
			cell: (data: Application) => data.requestorID || '',
			sortingField: 'requestorID'
		},
		{
			id: 'time',
			width: 100,
			header: 'Created Time',
			cell: (data: Application) => {
				if (data.creationTime) {
					const date = new Date(data.creationTime)
					const year = date.getFullYear()
					const month = (date.getMonth() + 1).toString().padStart(2, '0')
					const day = date.getDate().toString().padStart(2, '0')
					const hours = date.getHours().toString().padStart(2, '0')
					const minutes = date.getMinutes().toString().padStart(2, '0')
					const seconds = date.getSeconds().toString().padStart(2, '0')
				
					return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`
				}
				return ''
			},
			sortingField: 'creationTime'
		},
		{
			id: 'status',
			width: 100,
			header: 'Status',
			cell: (data: Application) => {
				const status = data.status
				switch (status) {
					case 'PENDING_APPROVAL':
						return (
							<StatusIndicator type="in-progress" colorOverride="blue">
								Pending Approval
							</StatusIndicator>
						)
					case 'APPROVED':
						return <StatusIndicator type="success">Approved</StatusIndicator>
					case 'REJECTED':
						return <StatusIndicator type="error">Rejected</StatusIndicator>
					default:
						return null
				}
			},
			sortingField: 'status'
		}
	]

	const tableActions = (
		<div
			style={{
				display: 'flex',
				width: 280,
				marginRight: 5
			}}
		>
			<Button variant="primary" onClick={() => navigate('/applications/new')}>
				New Application Environment
			</Button>
			<div
				style={{
					marginLeft: 7,
					marginTop: 4
				}}
			>
				<Popover
					fixedWidth
					position="right"
					triggerType="custom"
					content={
						<p>
							Click the following link for a step-by-step guide on application
							onboarding:
							<a
								href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/developer-enablement/application-onboarding/application_onboarding/`}
								target="_blank"
								rel="noopener noreferrer"
								aria-label="Plus"
								style={{
									marginLeft: '5px',
									marginTop: '25px'
								}}
							>
								LINK
							</a>
						</p>
					}
				>
					<Icon name="status-info" />
				</Popover>
			</div>
		</div>
	)


	return (
		<Table
			header="Applications"
			columnDefinitions={columnDefinitions}
			items={applications || []}
			actions={tableActions}
			disableRowSelect
			sortingDisabled={false}
			loading={isLoading}
		/>
	)
}

export default ApplicationOnboarding
